<template>
  <component :is="props.as" ref="slider" class="shared-drag-and-slide-container" :style="rootStyles">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue';
import type {
  IPropsSharedDragAndSlideContainer,
  IPropsSharedDragAndSlideContainerEmits,
} from './SharedDragAndSlideContainer.types';
import type { TPossibleNull } from '~/types/Shared.types';

const { isMobileOrTablet } = useDevice();
const { toPx } = GlobalUtils.Converting;

const props = withDefaults(defineProps<IPropsSharedDragAndSlideContainer>(), {
  as: 'div',
  behavior: 'by-scroll-properties',
  bounceMultiplier: 0.95,
  bounced: true,
  mobile: true,
  mobileEvents: false,
  preventDefault: true,
  smoothed: true,
  smoothingSpeed: 0.95,
  speed: 1.5,
});

const emits = defineEmits<IPropsSharedDragAndSlideContainerEmits>();

/* Сам слайдер */
const slider = ref<HTMLDivElement>();

/* Нажали ли мы на правую кнопку для grabbing */
let isMouseDowned = false;
/* Сделали ли touch для grabbing */
let isTouched = false;

/* Начальная позиция мыши по x */
let startXPoint = 0;
/* Предыдущее значение скролла, относильно левой стороны */
let prevScrollLeft = 0;
/* Разница итоговой прокрутки от предыдущей для smooth прокрутки */
let scrollDifference = 0;
/* Значение прокрутки на моменте, когда мы нажали на мышь */
let initialScrollLeft = 0;

/* Нваходится ли прокрутка точно в одном из двух концов */
let isAtCorners = false;
/* Можно ли скролить элементы */
let isTargetAlreadyScrollable = false;
/* Сохранение значений requestAnimationFrame для прекращения уже запущенных фреймов */
let requestAnimationID = 0;

/* Для style передвижения */
/* Предыдущий ивент для подсчета дистанции прохода ( если bounced = false ) */
let previousEvent: TPossibleNull<MouseEvent | TouchEvent> = null;

const rootStyles = computed(() => {
  if (isMobileOrTablet) {
    return {
      overflowX: 'scroll',
    } as CSSProperties;
  }
  return {};
});

const isBehaviorByStylesProperties = computed(() => props.behavior === 'by-styles-properties');

const checkTouchEvent = (event: Event) => {
  return 'TouchEvent' in window && event instanceof window.TouchEvent;
};

/* Функция подсчета дистанции прохода мышки/тача при bounced = false ( эмитация скролла ) */
const calculateMovementX = (event: MouseEvent | TouchEvent) => {
  const currentX = checkTouchEvent(event)
    ? (event as TouchEvent).targetTouches[0].screenX
    : (event as MouseEvent).screenX;

  if (!previousEvent) {
    previousEvent = event;
    return 0;
  }

  const previousX = checkTouchEvent(previousEvent)
    ? (previousEvent as TouchEvent).targetTouches[0].screenX
    : (previousEvent as MouseEvent).screenX;

  previousEvent = event;

  return currentX - previousX;
};

/* Обработчик зажима кнопки мыши */
const handleDragAndSlideStart = (event: MouseEvent | TouchEvent) => {
  if (props.preventDefault) {
    event.preventDefault();
  }

  const target = slider.value;
  if (!target) return;
  if (isBehaviorByStylesProperties.value && target.offsetWidth <= window.innerWidth) return;

  /* Если начали снова ухватили элемент, значит отменяем прошлый фрейм */
  cancelSmoothSliding();

  if (isBehaviorByStylesProperties.value) previousEvent = null;

  const parent = target.parentElement;
  const isAlwaysBounced = props.bounced.toString() === 'always';
  isTargetAlreadyScrollable = isAlwaysBounced || (!!parent?.clientWidth && target.scrollWidth > parent.clientWidth);

  if (checkTouchEvent(event)) {
    startXPoint = (event as TouchEvent).targetTouches[0].pageX;
    isTouched = true;
  } else if (event instanceof MouseEvent) {
    startXPoint = event.pageX;
    isMouseDowned = true;
  }

  prevScrollLeft = target.scrollLeft;
  initialScrollLeft = target.scrollLeft;

  isAtCorners = target.scrollLeft === 0 || target.scrollLeft + target.clientWidth === target.scrollWidth;

  if (isBehaviorByStylesProperties.value && !props.bounced) {
    setSliderStyles({
      transition: '',
    });
  }

  if ((props.bounced || isAlwaysBounced) && isAtCorners && isTargetAlreadyScrollable) {
    setSliderStyles({
      left: toPx(0),
      position: 'relative',
      transition: '',
    });
  }
};

/* Обработчик движения мыши, когда кнопку уже зажали */
const handleDragAndSlideMovement = (event: MouseEvent | TouchEvent) => {
  event.preventDefault();

  if (event instanceof MouseEvent && !isMouseDowned) return;
  if (checkTouchEvent(event) && !isTouched) return;

  const target = slider.value;
  if (!target) return;
  if (isBehaviorByStylesProperties.value && target.offsetWidth <= window.innerWidth) return;

  const pageX = checkTouchEvent(event) ? (event as TouchEvent).targetTouches[0].pageX : (event as MouseEvent).pageX;
  const moveX = checkTouchEvent(event) ? (event as TouchEvent).targetTouches[0].clientX : (event as MouseEvent).x;

  /* Подсчет "пути", на который нужно сдвинуть скролл */
  /* Исскуственно замедлен засчет деления на 2 */
  const walk = ((pageX - startXPoint) / 2) * 1.5;

  const isAlwaysBounced = props.bounced.toString() === 'always';

  if (isAtCorners && (props.bounced || isAlwaysBounced) && isTargetAlreadyScrollable) {
    const isLeftCornerAndMove = target.scrollLeft === 0 && moveX > startXPoint;
    const isRightCornerAndMove = target.scrollLeft + target.clientWidth === target.scrollWidth && moveX < startXPoint;

    if (isLeftCornerAndMove || isRightCornerAndMove) {
      /* Для эффекта баунса */
      return Promise.resolve().then(() => {
        setSliderStyles({
          left: toPx(walk - walk * props.bounceMultiplier),
        });
      });
    }
  }

  /* Если bounced = false - эмитируем скролл */
  if (isBehaviorByStylesProperties.value && !props.bounced) {
    const prevPos = +target.style.left.replace('px', '');
    const deltaX = calculateMovementX(event);

    let newPos = prevPos + deltaX;
    // Если пытаемся скролльнуть дальше левого края
    if (newPos > 0) {
      newPos = 0;
    }

    const targetEndPos = -(target.offsetWidth - window.innerWidth);
    // Если пытаемся скролльнуть дальше правого края
    if (newPos < targetEndPos) {
      newPos = targetEndPos;
    }

    setSliderStyles({
      left: toPx(newPos),
    });
  }

  prevScrollLeft = target.scrollLeft;

  /* Сам процесс изменения положения прокрутки */
  requestAnimationID = requestAnimationFrame(() => {
    target.scrollLeft = initialScrollLeft - walk;
    scrollDifference = target.scrollLeft - prevScrollLeft;
  });
};

/* Обработчик, когда отжали кнопку мыши */
const handleDragAndSlideEnd = () => {
  if (!isTouched && !isMouseDowned) return;

  const target = slider.value;
  if (!target) return;
  if (isBehaviorByStylesProperties.value && target.offsetWidth <= window.innerWidth) return;

  const isAlwaysBounced = props.bounced.toString() === 'always';

  if (isAtCorners && (props.bounced || isAlwaysBounced) && isTargetAlreadyScrollable) {
    setSliderStyles({
      left: toPx(0),
      transition: 'var(--default-duration) all',
    });

    isAtCorners = false;
  }

  if (isBehaviorByStylesProperties.value && !props.bounced) {
    setSliderStyles({
      transition: 'var(--default-duration) ease-in-out',
    });
  }

  isMouseDowned = false;
  isTouched = false;

  props.smoothed && startSmoothSliding();
};

/* Запускаем плавную прокрутку */
const startSmoothSliding = () => {
  cancelSmoothSliding();
  requestAnimationID = requestAnimationFrame(() => doSmoothSliding());
};

/* Сам процесс дополнительной прокрутки */
const doSmoothSliding = () => {
  if (!slider.value) return;

  slider.value!.scrollLeft += scrollDifference;
  scrollDifference *= props.smoothingSpeed;

  if (Math.abs(scrollDifference) > 0.5) {
    requestAnimationID = requestAnimationFrame(() => startSmoothSliding());
  }
};

/* Отменяем прошлый фрейм */
const cancelSmoothSliding = () => {
  cancelAnimationFrame(requestAnimationID);
};

/* Обработчик, когда мышь ушла с элемента прокрутки */
const handleGoingOutFromDragAndSlideElement = () => {
  handleDragAndSlideEnd();
};

const setSliderStyles = (styles: CSSProperties = {}) => {
  const target = slider.value!;

  for (const styleKey in styles) {
    // @ts-ignore
    target.style[styleKey] = styles[styleKey as keyof CSSProperties];
  }
};

onMounted(() => {
  const target = slider.value;
  if (!target) return;

  emits('init', target);

  if (!props.bounced) {
    setSliderStyles({
      transition: 'var(--default-duration) ease-in-out',
    });
  }

  /* Desktop listeners */
  target.addEventListener('mousedown', handleDragAndSlideStart);
  target.addEventListener('mousemove', handleDragAndSlideMovement);
  target.addEventListener('mouseleave', handleGoingOutFromDragAndSlideElement);
  document.addEventListener('mouseup', handleDragAndSlideEnd);

  /* Mobile listeners */
  if (props.mobileEvents) {
    target.addEventListener('touchstart', handleDragAndSlideStart);
    target.addEventListener('touchmove', handleDragAndSlideMovement);
    target.addEventListener('touchend', handleDragAndSlideEnd);
  }
});

onUnmounted(() => {
  const target = slider.value;
  if (!target) return;

  /* Desktop listeners */
  target.removeEventListener('mousedown', handleDragAndSlideStart);
  target.removeEventListener('mousemove', handleDragAndSlideMovement);
  target.removeEventListener('mouseleave', handleGoingOutFromDragAndSlideElement);
  document.removeEventListener('mouseup', handleDragAndSlideEnd);

  /* Mobile listeners */
  if (props.mobile) {
    target.removeEventListener('touchstart', handleDragAndSlideStart);
    target.removeEventListener('touchmove', handleDragAndSlideMovement);
    target.removeEventListener('touchend', handleDragAndSlideEnd);
  }
});

watch(
  () => props.bounced,
  (isBounced: boolean | string) => {
    if (!isBounced && isBounced.toString() !== 'always')
      setSliderStyles({
        left: '',
        position: undefined,
        transition: '',
      });
  },
);
</script>

<style src="./SharedDragAndSlideContainer.scss" scoped lang="scss" />
